import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { memo, Fragment } from "react";
import { Box, Button, ThemeProvider, createTheme } from "@mui/material";
import Login from "./Components/Login/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL

function appBarLabel(label, isAuth) {
  function handleLogout() {
    window.location.href = "/";
    sessionStorage.clear();
  }
  console.log(isAuth);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {label}
          </Typography>
          {isAuth && (
            <Button
              onClick={handleLogout}
              sx={{ width: "120px", height: "30px" }}
              size="small"
              variant="outlined"
              color="inherit"
            >
              Sign Out
            </Button>
          )}{" "}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
  },
});

function App() {
  const { palette } = createTheme();
  const { augmentColor } = palette;
  const [isAuth, setIsAuth] = React.useState(false);
  const createColor = (mainColor) =>
    augmentColor({ color: { main: mainColor } });

  const theme = createTheme({
    palette: {
      add: createColor("#F29557"),
      login: createColor("#FF6600"),
      icon: createColor("#ffffff"),
      progress: createColor("#fec400"),
      nav: createColor("#030192"),
      secondary_theme: createColor("#6d7de0"),
      to_be_approved: createColor("#a2efa2"),
      need_attension: createColor("#eda5a5"),
      secondaryToolBar: createColor("#E5E5FF"),
      chipColor: createColor("#ff8749"),
      chipColor2: createColor("#8484ff"),
      uploadBtn: createColor("#6d7de0"),
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)",
          },
        },
      },
    },
  });

  let windowRoute = window.location.pathname;

  let title = windowRoute.slice(1);
  title = title.charAt(0).toUpperCase() + title.slice(1);

  document.title =
    windowRoute === "/"
      ? "NimbleIO Login"
      : "NimbleIO " + title.replace(/_/g, " ");
  return (
    <ThemeProvider theme={theme}>
      {isAuth && (
        <AppBar position="static" color="primary">
          {appBarLabel("Question Paper Extraction", isAuth)}
        </AppBar>
      )}
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route
            path="/"
            element={
              <ProtectedRoute>
                <AuthenticatedLayout setIsAuth={setIsAuth} />
              </ProtectedRoute>
            }
          >
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>

          <Route path="*" element={<h1>404 Page is not found</h1>} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

const AuthenticatedLayout = () => {
  let windowRoute = useLocation().pathname;

  let title = windowRoute.slice(1);
  title = title.charAt(0).toUpperCase() + title.slice(1);

  document.title =
    windowRoute === "/"
      ? "NimbleIO Login"
      : "NimbleIO " + title.replace(/_/g, " ");

  return (
    <Fragment>
      <Box component={"main"} height={"100vh"} overflow={"hidden"}>
        <Box
          component={"section"}
          height="calc(100% - 139px)"
          p={3}
          overflow={"hidden"}
          sx={{ backgroundColor: "#f8f8ff" }}
        >
          <Box height={"100%"} overflow={"auto"}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = sessionStorage.getItem("user");
  children.props.setIsAuth(isAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};
