import React, { useEffect } from "react";
import MathJax from "react-mathjax";
import "./MCQRenderer.css";

const MCQRenderer = ({ data }) => {
 
  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
    }
  }, [data]); 

  if (!data || !data.response?.sections) {
    return <p>No data available</p>;
  }
 
  const MAX_TEXT_LENGTH = 80; 

 
  const handleLineBreaks = (text) => {
    if (!text) return text;
    let result = "";
    for (let i = 0; i < text.length; i += MAX_TEXT_LENGTH) {
      result += text.substring(i, i + MAX_TEXT_LENGTH) + "<br />";
    }
    return result;
  };

  const renderOptionContent = (option) => {
    const isImage = option.text.startsWith("https://"); // !Note:  Check if the option contains an S3 URL

    const formatLatexSpaces = (text) => {

      // !NOTE:Avoid adding `\:` near existing LaTeX commands or colons
      return text.replace(/(?<!\\)( )/g, "\\:").replace(/\\:\\:/g, "\\:"); //!Note: Replace single spaces only, avoid double `\:` issues
    };

    return isImage ? (
      <img
        src={option.text}
        alt="Option"
        style={{
          marginBottom: "5px",
        }}
      />
    ) : (
      <MathJax.Provider>
        <MathJax.Node formula={formatLatexSpaces(option.text.trim('\\'))} />
      </MathJax.Provider>
    );
  };
  
  // Render the matching pairs for match-the-following type questions
  const renderMatchTheFollowing = (matches) => {
    return (
      <div className="match-container">
        <table className="match-table">
          <thead>
            <tr>
              <th>Left</th>
              <th>Right</th>
            </tr>
          </thead>
          <tbody>
            {matches.map((match, index) => (
              <tr key={index}>
                <td>{match.left}</td>
                <td>{match.right}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
console.log(data)
  return (
    <div className="mcq-container">

      
      {/* Header */}
      <div>
      <header style={{ marginBottom: "20px" }}>
        <h1>{data.response?.headers?.title || "Question Paper"}</h1>
        <p>{data.response?.headers?.instructions || "Instructions not available."}</p>
        <hr />
      </header>

      {/* Class and Subject Info */}
      <section style={{ marginBottom: "20px" }}>
        <h3>Class: {data.response?.class_info?.class || "N/A"}</h3>
        <h3>Subject: {data.response?.class_info?.subject || "N/A"}</h3>
      </section>

      {/* Sections */}
      {data.response.sections.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          <h2>{section.section_title || "Section Title Not Available"}</h2>
          <p>Total Marks: {section.section_marks || "N/A"}</p>
          {section.section_instructions && <p>{section.section_instructions}</p>}
          <hr />

          {section.questions.map((question, questionIndex) => (
            <div
              key={question.id}
              className="question-box"
              style={{
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
              }}
            >
              {/* Render Question with MathJax */}
              <h3
                dangerouslySetInnerHTML={{
                  __html: `Q${questionIndex + 1}: ${handleLineBreaks(
                    question.question_text
                  )}`,
                }}
              ></h3>

              {/* Marks */}
              <p>
                <strong>Marks:</strong> {question.marks}
              </p>

              {/* Images for Question */}
              {question.images?.length > 0 && (
                <div>
                  {question.images.map((image, imageIndex) => (
                    <img
                      key={imageIndex}
                      src={image}
                      alt={`Question ${question.id} Image ${imageIndex}`}
                    />
                  ))}
                </div>
              )}

              {/* Render Options or Matches based on Question Type */}
              <div>
                {question.type === "MCQ" ? (
                  question.options.map((option, optionIndex) => (
                    <div
                      key={optionIndex}
                      className={`option-box ${option.is_correct ? "correct" : ""}`}
                    >
                      {renderOptionContent(option)}
                    </div>
                  ))
                ) : question.type === "match-the-following" ? (
                  renderMatchTheFollowing(question.matches)
                ) : (
                  <p>Unsupported question type: {question.type}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
      </div>
    </div>
  );
};

export default MCQRenderer;
