import React, { useState, useEffect } from 'react';
import '../../index.css'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';
import profile from '../../Assests/images/login.png';
import Email from '../../Assests/images/email.jpg';
import pass from '../../Assests/images/pass.png';

export default function Login() {
    const navigate = useNavigate();

    const [email, setEmailField] = useState('');
    const [password, setPasswordField] = useState('');

    async function userAuthentication(event) {
        event.preventDefault();
        let data = { email, password };

        if (email?.trim() !== '' && password?.trim() !== '') {
            await axios.post('/login', data).then((res) => {
                let result = res.data;
                if (result.status === 100) {
                    sessionStorage.setItem('user', JSON.stringify(result.user));
                    navigate('/dashboard');
                } else if (result.status === 403) {
                    alert('Incorrect Password');
                } else if (result.status === 404) {
                    alert('User Not Found or Invalid Mail-ID');
                }
            });
        }
    }

    return (
        <div className="main">
            <div className="sub-main">
                <div className="imgs">
                    <div className="container-image">
                        <img src={profile} alt="profile" className="profile" />
                    </div>
                </div>
                <h1>Login</h1>
                <div>
                    <img src={Email} alt="email" className="email" />
                    <input
                        value={email}
                        onChange={(e) => setEmailField(e.target.value)}
                        type="text"
                        placeholder="Enter your email"
                        className="name"
                    />
                </div>
                <div className="second-input">
                    <img src={pass} alt="password" className="email" />
                    <input
                        value={password}
                        onChange={(e) => setPasswordField(e.target.value)}
                        type="password"
                        placeholder="Enter your password"
                        className="name"
                    />
                </div>
                <div className="login-button">
                    <button onClick={(e) => userAuthentication(e)}>
                      
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
};
