import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import "./dashboard.css";
import { Divider } from '@mui/material';
import axios from "axios";
import MCQRenderer from './MCQRenederer';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton'; // For skeleton loader
import JSONViewer from './JSONViewer';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Dashboard() {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [documentUrl, setDocumentUrl] = React.useState(null);
  const [progress, setProgress] = React.useState(false);
  const [fileChangeStatus, setFileChangeStatus] = React.useState(false);
  const [mcqData, setMcqData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [responseData, setResponseData] = React.useState(null);
  const [mcqError, setMcqError] = React.useState(null);
  const [showJson,setShowJson] = React.useState(false)
  const [error, setError] = React.useState(false);
  const [convType, setConvType] = React.useState('md');


  const HandleSetJson=()=>{
    window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
    setShowJson(!showJson)
  }
  
  const handleChange = (event) => {
    setConvType(event.target.value);
    setFileChangeStatus(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileChangeStatus(false);
  };

  const handleError = () => {
    setError(true);
  };

  const handleUpload = async () => {
    let s3key = '';
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    setMcqData(null);
    setDocumentUrl(null);
    setProgress(true);

    try {
      await axios.post("/upload", formData)
        .then((res) => {
          setFileChangeStatus(true);
          setDocumentUrl(res.data.keyurl);
          setProgress(false);
          s3key = res.data.s3key;
        });


      setMcqData(null);
      setIsLoading(true); 
      setError(null); 
      setResponseData(null); 
      
      const response = await axios.post("/qp", {
        s3key,
        qtype: "multi",
        convtype: convType,
      });
      
      if (response.status === 200) {
        setMcqData(response.data);
      }

    } catch (err) {
      setError("An error occurred while processing the file.");
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div className="dashboard-container">
        {/* Left Grid - File Upload and Document Preview */}
        <div className="upload-section">
          <div className="upload-header">
            
            <Stack spacing={2} direction="row" >
              <input
                type="file"
                accept=".docx"
                onChange={handleFileChange}
                className="file-input"
              />
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={convType}
                  onChange={handleChange}
                >
                  <MenuItem value={'md'}>Mark Down</MenuItem>
                  <MenuItem value={'html5'}>HTML</MenuItem>
                </Select>
              </FormControl>
              {!fileChangeStatus && (
                <Button
                  onClick={handleUpload}
                  disabled={progress}
                  variant="contained"
                  size='small'
                  sx={{width:'150px',height:'30px',marginLeft:'auto',marginTop:"5px"}}
                >
                  {progress ? "Uploading" : "Upload"}
                </Button>
              )}
            </Stack>
          </div>


          {/* Skeleton Loader before upload */}
          {!fileChangeStatus && !documentUrl && !progress && (
            <Skeleton variant="rectangular" width="100%" height="500px" />
          )}

          {/* Document Preview */}
          {documentUrl && !error ? (
            <div className="preview-frame-container">
              <iframe
                src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(documentUrl)}&wdHideGridlines=true&wdHideHeadersFooters=true`}                className="preview-frame"
                onError={handleError}
                title="Document Preview"
                aria-label="Document viewer"
                role="document"
               
              />
            </div>
          ) : error ? (
            <p style={{ color: "red" }}>
              Failed to load the document. Ensure it's a supported file type (Word or PDF).
            </p>
          ) : (
            <p>Please upload a document to preview it here.</p>
          )}
        </div>

        {/* Right Grid - MCQ Data and Loading Indicator */}
        <div className="mcq-section">
          {isLoading ? (
            <div className="loading-section">
              <CircularProgress />
              <p>Processing your file...</p>
            </div>
          ) : mcqData && !showJson ? (
            <div><Button variant='contained' onClick={HandleSetJson} >Show Raw JSON</Button><MCQRenderer data={mcqData} convTYPE={convType} /></div>
          ) : 
          mcqData && showJson ?
          <div><Button variant='outlined' onClick={HandleSetJson} > Render JSON</Button><JSONViewer jsonData={mcqData} /></div>
          :
          error ? (
            <div className="error-message">
              <h3>Error</h3>
              <p>{error}</p>
            </div>
          ) : (
            <p>Upload a document to see the results.</p>
          )}
        </div>
      </div>
    </Box>
  );
}
