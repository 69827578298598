import JSONEditor from 'jsoneditor';
import React, { useEffect } from "react";
import 'jsoneditor/dist/jsoneditor.css';

export default function JSONViewer ({ jsonData }) {
    const editorRef = React.useRef();
    console.log(jsonData    )
    useEffect(() => {
        const editor = new JSONEditor(editorRef.current, { mode: 'view' });
        editor.set(jsonData);
        return () => editor.destroy();
    }, [jsonData]);

    return <div ref={editorRef} style={{ height: '80vh' }}></div>;
};
